import React from 'react'
import PropTypes from 'prop-types'
import './ClientSection.css'
import Button from '../Button/Button'
import Slider from 'react-slick'


const ClientSection = ({className}) => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 6000,
    fadeIn: false,
    autoplay: true,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };


return (

<div className={`clients flex w-full flex-col items-center xs:mb-0 md:mb-16 lg:mb-20 justify-center ${className}`}>
        <div className='flex w-full flex-wrap justify-center'>
          <div className='flex w-full justify-center text-center'>
            <p className='category-title'>Unsere Kunden</p>
          </div>
        </div>
        <div className="flex wrapper-logos-ref w-full justify-center max-w-6xl">
          <div className='flex logos-ref w-full logo-slider'>
            <Slider className="w-full" {...settings}>
              <div className="logo-entry logo-ref-jaeger">
                <div className="mx-auto logo-container w-full"></div>
                <p className="mx-auto logo-subline">Die Kommunikationsagentur im Healthcare-Bereich</p>
              </div>
              <div className="logo-entry logo-ref-shortcuts">
                <div className="mx-auto logo-container w-full"></div>
                <p className="mx-auto logo-subline">Die etablierte Full Service Agentur aus Berlin </p>
              </div>
              <div className="logo-entry logo-ref-franzwach">
                <div className="mx-auto logo-container w-full"></div>
                <p className="mx-auto logo-subline">Einer der Top 25 Personaldienstleister.</p>
              </div>
              <div className="logo-entry logo-ref-haba">
                <div className="mx-auto logo-container w-full"></div>
                <p className="mx-auto logo-subline">Tochter des Spielzeugherstellers HABA.</p>
              </div>
              <div className="logo-entry logo-ref-rtv">
                <div className="mx-auto logo-container w-full"></div>
                <p className="mx-auto logo-subline">Tochter des Bertelsmann Konzerns.</p>
              </div>
              <div className="logo-entry logo-ref-thwildau">
                <div className="mx-auto logo-container w-full"></div>
                <p className="mx-auto logo-subline">Größte Fachhochschule Brandenburgs.</p>
              </div>
              <div className="logo-entry logo-ref-archipinion">
                <div className="mx-auto logo-container w-full"></div>
                <p className="mx-auto logo-subline">Videoplattform des DETAIL Verlags für Architektur.</p>
              </div>
              <div className="logo-entry logo-ref-goeuro">
                <div className="mx-auto logo-container w-full"></div>
                <p className="mx-auto logo-subline">Bahn-, Bus- und Flugtickets vergleichen und buchen.</p>
              </div>
            </Slider>  
            <div class="preload">
          <div class="logo-ref-goeuro colorlogo-1"></div>
          <div class="logo-ref-thwildau colorlogo-2"></div>
          <div class="logo-ref-rtv colorlogo-3"></div>
          <div class="logo-ref-franzwach colorlogo-4"></div>
          <div class="logo-ref-haba colorlogo-5"></div>
          <div class="logo-ref-shortcuts colorlogo-7"></div>
          <div class="logo-ref-jaeger colorlogo-6"></div>
          <div class="logo-ref-archipinion"></div>
        </div>
          </div>
        </div>    
      </div>
    )
}

export default ClientSection